const priceSheet = [
    {
        id: 0,
        name: "Extra Large Upper",
        type: "Pane",
        desc: "> 27 sqft",
        price: 22.69,
        count: 0,
        totalPrice: 0
    },
    {
        id: 1,
        name: "Large Upper",
        type: "Pane",
        desc: "13 - 26 sqft",
        price: 15.26,
        count: 0,
        totalPrice: 0
    },
    {
        id: 2,
        name: "Medium Upper",
        type: "Pane",
        desc: "4 - 12 sqft",
        price: 7.94,
        count: 0,
        totalPrice: 0
    },
    {
        id: 3,
        name: "Small Upper",
        type: "Pane",
        desc: "1 - 3 sqft",
        price: 6.23,
        count: 0,
        totalPrice: 0
    },
    {
        id: 5,
        name: "Extra Small Upper",
        type: "Pane",
        desc: "< 1 sqft",
        price: 3.00,
        count: 0,
        totalPrice: 0
    },
    {
        id: 6,
        name: "Extra Large Lower",
        type: "Pane",
        desc: "> 27 sqft",
        price: 17.14,
        count: 0,
        totalPrice: 0
    },
    {
        id: 7,
        name: "Large Lower",
        type: "Pane",
        desc: "13 - 26 sqft",
        price: 10.72,
        count: 0,
        totalPrice: 0
    },
    {
        id: 8,
        name: "Medium Lower",
        type: "Pane",
        desc: "4 - 12 sqft",
        price: 5.88,
        count: 0,
        totalPrice: 0
    },
    {
        id: 9,
        name: "Small Lower",
        type: "Pane",
        desc: "1 - 3 sqft",
        price: 3.74,
        count: 0,
        totalPrice: 0
    },
    {
        id: 11,
        name: "Extra Small Lower",
        type: "Pane",
        desc: "< 1 sqft",
        price: 2.00,
        count: 0,
        totalPrice: 0
    },
    {
        id: 12,
        name: "Half",
        type: "Screen",
        desc: "",
        price: 2.18,
        count: 0,
        totalPrice: 0
    },
    {
        id: 13,
        name: "Half",
        type: "Screen",
        desc: "Remove from inside",
        price: 3.81,
        count: 0,
        totalPrice: 0
    },
    {
        id: 14,
        name: "Full",
        type: "Screen",
        desc: "Interior or exterior",
        price: 2.72,
        count: 0,
        totalPrice: 0
    },
    {
        id: 15,
        name: "Solar",
        type: "Screen",
        desc: "",
        price: 5.46,
        count: 0,
        totalPrice: 0
    },
    {
        id: 16,
        name: "Solar",
        type: "Screen",
        desc: "Screw-in",
        price: 7.67,
        count: 0,
        totalPrice: 0
    },
    {
        id: 17,
        name: "Wooden Lower",
        type: "Screen",
        desc: "",
        price: 6.55,
        count: 0,
        totalPrice: 0
    },
    {
        id: 18,
        name: "Wooden Upper",
        type: "Screen",
        desc: "",
        price: 13.10,
        count: 0,
        totalPrice: 0
    }
]

export {priceSheet}