export default function Counter(props) {
    return (
        <div className="counter">
            <h2 className="counter--title">{props.name} {props.type}</h2>
            {props.desc && <div className="counter--desc">{props.desc}</div>}
            <div className="counter--buttons">
                {props.name === "Extra Small Upper" ? <button className="button button--minus" onClick={props.handleBigMinus}>--</button>
                    : props.name === "Extra Small Lower" ? <button className="button button--minus" onClick={props.handleBigMinus}>--</button>
                    : null}
                <button className="button button--minus" onClick={props.handleMinus}>-</button>
                <text className="counter--count">{props.count}</text>
                <button className="button button--plus" onClick={props.handlePlus}>+</button>
                {props.name === "Extra Small Upper" ? <button className="button button--plus" onClick={props.handleBigPlus}>++</button>
                    : props.name === "Extra Small Lower" ? <button className="button button--plus" onClick={props.handleBigPlus}>++</button>
                    : null}
                <button className="button button--reset" onClick={props.handleReset}>Reset</button>
            </div>
        </div>
    )
}